.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// BASIC CLASSES//

.flex {
  display: flex;
  width: auto;
}

.full {
  width: auto;
}

.no-padding-top {
  padding-top: 0 !important;
}

.left-align {
  text-align: left;
  margin: 0 5em;
}
.right-align {
  text-align: right;
  margin: 0 5em;
}
.margin-top{
  margin-top: 1em;
}

.border {
  border: 1px solid white;
}

.thirds {
  width: 33.33%;
}
.two-thirds {
  width: 66.66%;
}

.center {
  margin: auto;
  display: block;
}

.white {
  color: white;
}

.bold{
  font-weight: 500;
}

.icon {
  font-size: 2em;
}

a {
  color: white;
  text-decoration: none;
}

h1 {
  font-size: 2em;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 2em;
}

h2 {
  font-size: 1.5em;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 0.25em;
}

h3 {
  margin-top: 0;
  font-weight: 200;

}

h4 {
  margin: 0.5em 0;
  font-weight: 300;
}

h6 {
  margin-top: 0;
}

img {
 &.screenshot{
  width: 100%
 }
  max-width: 100%;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  position: absolute;
  width: 100%;
  font-size: 30px;
  color: white;
  z-index: 2;

  .header-item-container {
    display: flex;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    position: relative;
    align-items: center;
    justify-content: space-between;
  }
}

.footer-section {
  background-color: #b6dad2;
  padding: 1em 0;
}
.body-section {

  &.hero-image{
    background-color:#b6dad2;
    color: white;
  }

  background-color: white;
  color: #3A4B59;
  padding: 8em 0;

  .background {
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.img {
      opacity: 0.5;
    }
  }
}

.portfolio-section{
  margin-bottom: 10em;
}

.Title {
  padding-left: 2em;
}

.text {
  &.left-align {
    text-align: left;
    margin: 0 5em;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  grid-row-gap: 5em;
  grid-column-gap: 5em;
}

.grid-item {
color: white;
  font-size: 30px;
  text-align: center;
  width: 100%;
  min-width: 11em;
  height: 10em;
  position: relative;

  &:hover {
    .overlay {
      background-color: rgba(0,0,0,0.5);
    }

    .title-hover {
      display: block;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }

  .title-hover {
    display: none;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;
    margin-top: 2em;

    hr {
      border-top: 4px solid white;
      margin: 0.5em;
    }
  }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 2;
  cursor: pointer;
  }
}

@media only screen and (max-width: 400px) {
  .text {
    &.left-align {
      margin: 0px 3em;
    }
  }

  .grid-item {
    min-width: 6em;
    height: 5em;
  }

  .desktop-only {
    display: none;
  }
}

@media only screen and (max-width: 1600px) {
  .grid-container {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 800px) {
  .grid-container {
    grid-template-columns: auto;
  }
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 18px;
  }
  .flex.responsive {
    display: block;

    .thirds, .two-thirds {
      width: auto;
    }
  }
}

.links {
  position: absolute;
  right: 10%;
  font-size: 20px;

  a {
      margin-left: 30px;

      &:first-child {
        margin-left: 0;
      }
      &:hover {
        cursor: pointer;
      }
  }
}

.section-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-bottom: 1em;

  .center {
    align-content: center;
  }

  .footer-component.thirds {
    width: 33.33%;
  }

  .icon {
    font-size: 2em;

    &.white {
      color: white;
    }
  }

.tooltip-trigger {
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }

  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;

    position: absolute;
    z-index: 1;

    width: 180px;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;

    &:after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}

.grow {
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

}
